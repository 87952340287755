import { css } from '@emotion/react'

const style = {
  box: css`
    background-color: white;
  `,
  slug: slug => css`
    ${slug === 'truemoveh' &&
    `
      color: #ff251b;
    `}
    ${slug === 'trueonline' &&
    `
      color: #4CA8F8;
    `}
    font-weight: 600;
  `,
  header: css`
    font-family: var(--text-normal);
    font-size: var(--fs-4);
    padding-left: var(--bs-gutter-x, 0.75rem);
    padding-right: var(--bs-gutter-x, 0.75rem);
    text-align: left;
  `,
  headerContent: css`
    font-family: var(--text-normal);
    font-size: var(--fs-4);
    padding-left: var(--bs-gutter-x, 0.75rem);
    padding-right: var(--bs-gutter-x, 0.75rem);
    text-align: left;
  `,
}

export default style
