import { Container } from 'swp-components/lib/components/bootstrap'
import Footer from 'swp-components/lib/components/footer'
import { DataLayerProvider } from 'swp-components/lib/contexts/data-layer'

import ChatShop from '@/components/chat-shop'
import CookiesConsent from '@/components/cookies-consent'
import Header from '@/components/header'
import Newrelic from '@/components/newrelic-agent'
import style from '@/components/news-events/detail.style'
import SectionHeading from '@/components/section-heading'
import Seo from '@/components/seo'
import { TagTipsGrid } from '@/components/tips-grid'
import useHomePageDataLayer from '@/hooks/data-layers/use-home-page'
import NotFoundPage from '@/pages/404'
import strapi from '@/strapi'

const TOLTipsTag = ({ serverData }) => {
  let [
    seo,
    headerScript,
    brandHeaders,
    productServices,
    footerHeaders,
    chatShop,
    tagExist,
    tagContent,
    mariConfig,
  ] = strapi.parseContents(serverData.contents)

  let { handleMessage, createScopeProps } = useHomePageDataLayer()

  if (tagExist == null || tagExist.length <= 0) {
    return <NotFoundPage />
  }

  tagContent.map(a => {
    a.link = {
      url: `${serverData.locale !== 'th' ? '/en' : ''}/trueonline/tips/${
        a.slug
      }`,
      newTab: true,
    }
    a.startDate = a.updatedAt
  })

  return (
    <>
      <Newrelic />
      <DataLayerProvider handler={handleMessage}>
        <Seo
          seo={tagExist.seo ? tagExist.seo : seo.mainSeo}
          script={headerScript.script}
        />
        <Header items={brandHeaders} services={productServices} />

        {tagContent.length > 0 && (
          <Container>
            <div className="mt-5" css={style.box}>
              <SectionHeading tag="h1">{'QUICK TIPS'}</SectionHeading>
              <SectionHeading
                tag="h2"
                variant="underline"
                className="mt-1"
                level="2"
              >
                {'เคล็ดลับทิปส์เด็ด True Online'}
              </SectionHeading>
            </div>
            <div className="my-3" css={style.box}>
              <h2 css={style.header}>
                {'แท็ก:'} <span css={style.slug}>{tagExist.name}</span>
              </h2>
              <TagTipsGrid
                article={tagContent}
                slug={serverData.slug}
                locale={serverData.locale}
              ></TagTipsGrid>
            </div>
          </Container>
        )}

        {footerHeaders && <Footer className="pb-4" items={footerHeaders} />}
        {chatShop && (
          <ChatShop
            content={chatShop}
            mariPlatform={ serverData.locale == 'th' ? "mari_tmh" : "truemoveh" }
            mariConfig={mariConfig}
          />
        )}
        <CookiesConsent />
      </DataLayerProvider>
    </>
  )
}

export default TOLTipsTag

export const getServerData = async ({ params, pageContext }) => {
  let contents = await strapi.getContents([
    'seo',
    'header-script',
    'brand-headers',
    'product-services',
    'footer-headers',
    'chat-and-shop',
    `tol-news-and-tips-tags/${params.slug}`,
    `tol-news-and-tips-tags/${params.slug}/tol_news?_start=0&_limit=6&_locale=${pageContext.locale}`,
    'mari-chat-and-shop-config',
  ])

  let slug = params.slug
  return {
    props: { contents, slug: slug, locale: pageContext.locale },
  }
}
